import styled from 'styled-components';

export const H1Heading = styled.h1`
  display: flex;
  color: #461a1d;
`;

export const H2Heading = styled.h2`
  display: flex;
  color: #461a1d;
  font-size: 1.6rem;
  margin: 0;
  margin-top: 0.5rem;
`;

export const Paragraph = styled.div`
  margin: 1rem 0;
`;

export const Icon = styled.img`
  display: flex;
`;
